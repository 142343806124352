import React, { Component } from "react";
import { Link } from "react-router-dom";

const PortfolioListContent = [
    {
        image: 'image-1',
        title: 'Kixy',
        link: 'https://www.kixy.com',
        category: 'Taking your money to new heights!'
    },
    {
        image: 'image-2',
        link: 'https://www.relife.com/market/',
        title: 're.life market',
        category: 'A virtual marketplace for business-to-business buying and selling of recovered commodities '
    },
    {
        image: 'image-3',
        title: 'Cloudhoods',
        link: 'https://www.cloudhoods.com',
        category: 'The Women Empowerment Network'
    },
    {
        image: 'image-4',
        title: 'Limelines',
        link: 'https://www.limelines.com',
        category: 'FRESH NEWS FROM OUR REGION'
    },
    {
        image: 'image-5',
        title: 'Schedex',
        link: 'https://www.schedex.me',
        category: 'Scheduling the future of work'
    },
    {
        image: 'image-6',
        title: 're.life collect',
        link: 'https://www.relife.com/collect/',
        category: 'A mobile app for residents or businesses looking to move heavy, bulky items from one location to another, or to dispose of unwanted items'
    }
]

class PortfolioList extends Component {
    render() {
        const { column, styevariation } = this.props;
        const list = PortfolioListContent.slice(0, this.props.item);
        return (
            <React.Fragment>
                {list.map((value, index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail ${value.image}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>{value.category}</p>
                                    <h4><a href={value.link}>{value.title}</a></h4>
                                    <div className="portfolio-button">
                                        <a className="rn-btn" href={value.link}>View Details</a>
                                    </div>
                                </div>
                            </div>
                            {/* <Link className="link-overlay" to={value.link}></Link> */}
                        </div>
                    </div>
                ))}

            </React.Fragment>
        )
    }
}
export default PortfolioList;