import React, { Component, Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { Link } from "react-router-dom";
import { FiChevronUp } from "react-icons/fi";
import HeaderTwo from "../component/header/HeaderTwo";
import FooterTwo from "../component/footer/FooterTwo"
import Brand from "../elements/Brand";
import PortfolioList from "../elements/portfolio/PortfolioList";
import TabTwo from "../elements/tab/TabTwo";
import ContactOne from "../elements/contact/ContactOne";
import Helmet from "../component/common/Helmet";
import { ProgressBar } from "react-bootstrap";

const SlideList = [
    {
        textPosition: 'text-left',
        category: 'React Native and Javascript Developer',
        title: 'Hello, I’m <span>Kasem</span> Welcome to my World.',
        description: '',
        buttonText: '',
        buttonLink: ''
    }
]
class PersonalPortfolio extends Component {
    render() {
        let title = 'About Me',
            description = 'I graduated with a Bachelor of Computer Science degree from the American University of Science and Technology and was very active in many facets of university life. I completed a number of internships, and by the end of my second year I was working for a company involved in the seeded funding of startups, which gave me a great exposure to the world of startups. My experiences gave me an overview of the expectations of developers along with the techniques through which one can achieve positions working within companies in Silicon Valley, United Arab Emirates, and other countries in the MENA region. Given my extensive experience with start-up companies, I am ready to transition to a more long-term projects.';
        return (
            <Fragment>
                <Helmet pageTitle="Personal Portfolio" />

                <HeaderTwo logo="symbol-dark" color="color-black" />

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value, index) => (
                        <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--16" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            {value.title ? <h1 className="title" dangerouslySetInnerHTML={{ __html: value.title }}></h1> : ''}
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
                {/* End Slider Area   */}

                {/* Start About Area */}
                <div className="about-area about-position-top pb--120  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-5.JPG" alt="About Images" />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner pt--100">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                        </div>
                                        <div className="row mt--30">
                                            <TabTwo tabStyle="tab-style--1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">What do I do best?</h2>
                                            <p className="description">During my years my recent years of experience I was working on perfecting the mobile development process from different aspects focusing on performance, usability and UX. </p>
                                        </div>
                                        <div className="row mt--30">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">My Skills.</h3>
                                                    <div className="rn-progress-bar progress-bar--3">
                                                        <div className="single-progress custom-color--1">
                                                            <h6 className="title">REACT NATIVE</h6>
                                                            <ProgressBar now={100} />
                                                            <span className="label">100%</span>
                                                        </div>

                                                        <div className="single-progress custom-color--2">
                                                            <h6 className="title">ReactJS</h6>
                                                            <ProgressBar now={90} />
                                                            <span className="label">80%</span>
                                                        </div>

                                                        <div className="single-progress custom-color--3">
                                                            <h6 className="title">AWS services & Amplify Integration</h6>
                                                            <ProgressBar now={100} />
                                                            <span className="label">100%</span>
                                                        </div>

                                                        <div className="single-progress custom-color--4">
                                                            <h6 className="title">CSS</h6>
                                                            <ProgressBar now={90} />
                                                            <span className="label">90%</span>
                                                        </div>

                                                        <div className="single-progress custom-color--4">
                                                            <h6 className="title">SDK Integrations</h6>
                                                            <ProgressBar now={90} />
                                                            <span className="label">90%</span>
                                                        </div>

                                                        <div className="single-progress custom-color--4">
                                                            <h6 className="title">Agile</h6>
                                                            <ProgressBar now={100} />
                                                            <span className="label">100%</span>
                                                        </div>

                                                        <div className="single-progress custom-color--2">
                                                            <h6 className="title">ReactJS</h6>
                                                            <ProgressBar now={90} />
                                                            <span className="label">80%</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-5 mt_md--40 mt_sm--40">
                                    <div className="thumbnail">
                                        {/* <img className="w-100" src="/assets/images/about/about-3.jpg" alt="About Images" /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start Portfolio Area */}
                <div className="portfolio-area pb--60 bg_color--1">
                    <div className="portfolio-sacousel-inner mb--55 mb_sm--0">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                        <h2 className="title">My Latest Projects</h2>
                                        {/* <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/* Start Portfolio Area */}
                <div className="portfolio-area pb--120 bg_color--1">
                    <ContactOne />
                </div>
                {/* End Portfolio Area */}

                <FooterTwo />

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}

export default PersonalPortfolio;