import React, { Component } from "react";

class ContactOne extends Component{
    constructor(props){
        super(props);
        this.state = {
            rnName: '',
            rnEmail: '',
            rnSubject: '',
            rnMessage: '',
        };
    }
    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">Contact Me.</h2>
                                <p className="description">I am available for freelance work. Connect with me via phone: <a href="tel:+447384144415">+447384144415</a> or email:
                                    <a href="mailto:contact@kasemyassine.com"> contact@kasemyassine.com</a> </p>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactOne;