import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component {
    render() {
        let
            tab1 = "Main skills",
            tab2 = "Experience",
            tab3 = "Education & Certification";
        const { tabStyle } = this.props
        return (
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a href="">Reactjs and React Native<span> - Development</span></a>
                                                    Javascript has always been in my interests in which made work harder on getting to where I am.
                                                </li>
                                                <li>
                                                    <a href="">Javascript & Typescript <span> - Development</span></a>
                                                    As part of being eager about learning React Javascript knowledge has always grown in order to reach higher potentials, but that turned out not to be enough so to handle error before runtime Typescript became my first focus.
                                                </li>
                                                <li>
                                                    <a href="">CI/CD <span> - Development</span></a>
                                                    In order to facilitate development process CI/CD was necessary to maintain development speed and personal performance.
                                                </li>
                                                <li>
                                                    <a href="">Managing Admin dashboards<span> - Development</span></a>
                                                    As a developer I was responsible of both AppStore and PlayStore consoles in order to release new application. Managing AWS services and looking deep into data and automated services
                                                </li>
                                                <li>
                                                    <a href="">AWS - Amazon Web Services <span> - DevOps</span></a>
                                                    After working with Zero&One knowing that it's an AWS partner I was exposed to all web services in which that knowledge kept growing with Kixy LTD
                                                </li>
                                                <li>
                                                    <a href="">User experience design <span> - Development</span></a>
                                                    Implementing designs and meeting our UI/UX team expectation is one of my pleasures.
                                                </li>
                                                <li>
                                                    <a href="">Interaction design <span> - Animation</span></a>
                                                    Digging deep into animation and getting through lottie animations was always in my interests
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a href="https://www.kixy.com">Mobile Software Engineer<span> - Kixy LTD</span></a> Aug 2021 - Current
                                                </li>
                                                <li>
                                                    <a href="https://www.zeroandone.me">Sr. Mobile Software Engineer<span> - Zero&One</span></a> Jul 2020 - Aug 2021
                                                </li>
                                                <li>
                                                    <a href="https://www.limelines.com">Mobile Lead <span> - Limelines</span></a> Aug 2019 - Jul 2020
                                                </li>
                                                <li>
                                                    <a href="https://www.limelines.com">Mobile Software Engineer<span> - Limelines </span></a> Jun 2018- Aug 2019
                                                </li>
                                                <li>
                                                    <a href="https://sowlutions.com">Mobile Software Engineer<span> - Sowlutions </span></a> Aug 2017- Jun 2018
                                                </li>
                                                <li>
                                                    <a href="https://www.cloudhoods.com">Mobile Developer (Freelance) <span> - Cloudhoods</span></a> Feb 2020 - Aug 2020
                                                </li>
                                                <li>
                                                    <a href="https://www.schedex.me">Mobile Developer (Freelance)<span> - Schedex </span></a> Nov 2019- Feb 2020
                                                </li>
                                                <li>
                                                    <a href="https://flokisolutions.com/">Mobile Developer (Freelance)<span> - MR Grocers / Floki Solutions </span></a> Apr 2019 - Jan 2020
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a href="">BSc In CS<span> - American University of Science and Technology</span></a> 2018
                                                </li>
                                                <li>
                                                    <a href="">CCNA 1<span> - Cisco certified network associate</span></a> 2017
                                                </li>
                                                <li>
                                                    <a href="">CCNA 2<span> - Cisco certified network associate</span></a> 2017
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;